import React from "react";

import resumeSvg from "../../assets/resume.png";
import logo from "../../assets/logo-dark.png";


import styles from "./Header.module.css";

function Header() {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
      <img src={logo} alt="loho-horn-talent" />

        <p className={styles.heading}>
          A <span>Resume</span> that describes your true self!
        </p>
        <p className={styles.heading}>
          Make your own resume now. <span>It's free</span>
        </p>
      </div>
      <div className={styles.right}>
        <img src={resumeSvg} alt="Resume" />
      </div>
    </div>
  );
}

export default Header;
