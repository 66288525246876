import React from "react";

import Header from "./components/Header/Header";
import Body from "./components/Body/Body";

import "./App.css";

function App() {
  return (
    <>
    <div className="App">
      <Header />
      <Body />
      <footer style={{background: '#202943', width:'100%', height:'45px',color: 'white', textAlign:'center', padding:'1em'}}>
          All rights reserved © Horn Talent
    </footer>
    </div>
    
    </>
  );
}

export default App;
